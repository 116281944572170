"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.sort.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var getJoinedModule = function getJoinedModule(widgets, _ref) {
  var _ref$query = _ref.query,
      query = _ref$query === void 0 ? {} : _ref$query,
      product = _ref.product;
  var mWidgets = widgets.map(function (widget) {
    var _widget$params, _widget$params2, _widget$params3, _widget$params4, _widget$params5, _widget$params6, _widget$params7, _widget$params8, _widget$params9, _widget$params10, _widget$params11, _widget$params12, _widget$params13, _widget$params14, _widget$params15, _widget$params16, _widget$params17, _widget$params18, _widget$params19, _widget$params20;

    if ((widget === null || widget === void 0 ? void 0 : widget.widgets) == null) {
      switch (widget === null || widget === void 0 ? void 0 : widget.module) {
        case 'products':
          return _objectSpread(_objectSpread({}, widget), {}, {
            params: _objectSpread({
              ids: (query === null || query === void 0 ? void 0 : query.ids) || (widget === null || widget === void 0 ? void 0 : (_widget$params = widget.params) === null || _widget$params === void 0 ? void 0 : _widget$params.ids),
              includedAllTags: (query === null || query === void 0 ? void 0 : query.includedAllTags) || (widget === null || widget === void 0 ? void 0 : (_widget$params2 = widget.params) === null || _widget$params2 === void 0 ? void 0 : _widget$params2.includedAllTags),
              limit: +(query === null || query === void 0 ? void 0 : query.limit) || +(widget === null || widget === void 0 ? void 0 : (_widget$params3 = widget.params) === null || _widget$params3 === void 0 ? void 0 : _widget$params3.limit),
              offset: +(query === null || query === void 0 ? void 0 : query.offset) || +(widget === null || widget === void 0 ? void 0 : (_widget$params4 = widget.params) === null || _widget$params4 === void 0 ? void 0 : _widget$params4.offset),
              sort: (query === null || query === void 0 ? void 0 : query.sort) || (widget === null || widget === void 0 ? void 0 : (_widget$params5 = widget.params) === null || _widget$params5 === void 0 ? void 0 : _widget$params5.sort),
              price: (query === null || query === void 0 ? void 0 : query.price) || (widget === null || widget === void 0 ? void 0 : (_widget$params6 = widget.params) === null || _widget$params6 === void 0 ? void 0 : _widget$params6.price),
              search: (query === null || query === void 0 ? void 0 : query.search) || !(widget !== null && widget !== void 0 && (_widget$params7 = widget.params) !== null && _widget$params7 !== void 0 && _widget$params7.ids) && (widget === null || widget === void 0 ? void 0 : (_widget$params8 = widget.params) === null || _widget$params8 === void 0 ? void 0 : _widget$params8.search),
              tags: (query === null || query === void 0 ? void 0 : query.tags) || !(widget !== null && widget !== void 0 && (_widget$params9 = widget.params) !== null && _widget$params9 !== void 0 && _widget$params9.ids) && (widget === null || widget === void 0 ? void 0 : (_widget$params10 = widget.params) === null || _widget$params10 === void 0 ? void 0 : _widget$params10.tags)
            }, widget !== null && widget !== void 0 && (_widget$params11 = widget.params) !== null && _widget$params11 !== void 0 && _widget$params11.ids ? {
              sort: 'selections'
            } : {})
          });

        case 'products-controlled':
          return _objectSpread(_objectSpread({}, widget), {}, {
            params: _objectSpread({
              ids: widget === null || widget === void 0 ? void 0 : (_widget$params12 = widget.params) === null || _widget$params12 === void 0 ? void 0 : _widget$params12.ids,
              includedAllTags: widget === null || widget === void 0 ? void 0 : (_widget$params13 = widget.params) === null || _widget$params13 === void 0 ? void 0 : _widget$params13.includedAllTags,
              limit: +(widget === null || widget === void 0 ? void 0 : (_widget$params14 = widget.params) === null || _widget$params14 === void 0 ? void 0 : _widget$params14.limit),
              offset: +(widget === null || widget === void 0 ? void 0 : (_widget$params15 = widget.params) === null || _widget$params15 === void 0 ? void 0 : _widget$params15.offset),
              price: widget === null || widget === void 0 ? void 0 : (_widget$params16 = widget.params) === null || _widget$params16 === void 0 ? void 0 : _widget$params16.price
            }, widget !== null && widget !== void 0 && (_widget$params17 = widget.params) !== null && _widget$params17 !== void 0 && _widget$params17.ids ? {
              sort: 'selections'
            } : {
              search: widget === null || widget === void 0 ? void 0 : (_widget$params18 = widget.params) === null || _widget$params18 === void 0 ? void 0 : _widget$params18.search,
              tags: widget === null || widget === void 0 ? void 0 : (_widget$params19 = widget.params) === null || _widget$params19 === void 0 ? void 0 : _widget$params19.tags,
              sort: widget === null || widget === void 0 ? void 0 : (_widget$params20 = widget.params) === null || _widget$params20 === void 0 ? void 0 : _widget$params20.sort
            })
          });

        case 'product':
          {
            return _objectSpread(_objectSpread({}, widget), {}, {
              productData: product
            });
          }

        case 'product-info':
          {
            return _objectSpread(_objectSpread({}, widget), {}, {
              productData: product,
              edition: 'detail'
            });
          }

        case 'product-carousell':
          {
            return _objectSpread(_objectSpread({}, widget), {}, {
              coverImage: product === null || product === void 0 ? void 0 : product.coverImage,
              galleries: product === null || product === void 0 ? void 0 : product.galleries
            });
          }

        case 'product-collections':
          {
            return _objectSpread(_objectSpread({}, widget), {}, {
              galleries: product === null || product === void 0 ? void 0 : product.galleries,
              title: product === null || product === void 0 ? void 0 : product.title
            });
          }

        case 'product-html':
          {
            return _objectSpread(_objectSpread({}, widget), {}, {
              product: product
            });
          }

        case 'product-service':
          {
            return _objectSpread(_objectSpread({}, widget), {}, {
              productId: query === null || query === void 0 ? void 0 : query.pId
            });
          }

        default:
          return widget;
      }
    }

    return {
      widgets: getJoinedModule(widget === null || widget === void 0 ? void 0 : widget.widgets, {
        query: query,
        product: product
      })
    };
  });
  return mWidgets;
};

var _default = getJoinedModule;
exports["default"] = _default;