"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _divider = _interopRequireDefault(require("@meepshop/divider"));

var _draftText = _interopRequireDefault(require("@meepshop/draft-text"));

var _facebookWall = _interopRequireDefault(require("@meepshop/facebook-wall"));

var _googleMap = _interopRequireDefault(require("@meepshop/google-map"));

var _iframe = _interopRequireDefault(require("@meepshop/iframe"));

var _productCarousel = _interopRequireDefault(require("@meepshop/product-carousel"));

var _productContent = _interopRequireDefault(require("@meepshop/product-content"));

var _productCollections = _interopRequireDefault(require("@meepshop/product-collections"));

var _productDraftText = _interopRequireDefault(require("@meepshop/product-draft-text"));

var _productIframe = _interopRequireDefault(require("@meepshop/product-iframe"));

var _productQa = _interopRequireDefault(require("@meepshop/product-qa"));

var _productVideo = _interopRequireDefault(require("@meepshop/product-video"));

var _smartConversion = _interopRequireDefault(require("@meepshop/smart-conversion"));

var _socialMedia = _interopRequireDefault(require("@meepshop/social-media"));

var _socialThumbs = _interopRequireDefault(require("@meepshop/social-thumbs"));

var _unavailable = _interopRequireDefault(require("@meepshop/unavailable"));

var _video = _interopRequireDefault(require("@meepshop/video"));

var _image = _interopRequireDefault(require("../image"));

var _carousel = _interopRequireDefault(require("../carousel"));

var _landingPage = _interopRequireDefault(require("../landingPage"));

var _imageText = _interopRequireDefault(require("../imageText"));

var _productInfo = _interopRequireDefault(require("../productInfo"));

var _productList = _interopRequireDefault(require("../productList"));

var _activity = _interopRequireDefault(require("../activity"));

var _menu = _interopRequireWildcard(require("../menu"));

var _handleProductQA = _interopRequireDefault(require("./utils/handleProductQA"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var _default = {
  image: _image["default"],
  iframe: _iframe["default"],
  googlemap: _googleMap["default"],
  carousel: _carousel["default"],
  divider: _divider["default"],
  'facebook-wall': _facebookWall["default"],
  'landing-page': _landingPage["default"],
  'live-video': _unavailable["default"],
  'live-video-comments': _unavailable["default"],
  'product-html': _productIframe["default"],
  'social-thumbs': _socialThumbs["default"],
  imagetext: _imageText["default"],
  'social-media': _socialMedia["default"],
  'video-core': _video["default"],
  'product-collections': _productCollections["default"],
  'product-carousell': _productCarousel["default"],
  'product-draftText': _productDraftText["default"],
  'product-info': _productInfo["default"],
  'product-content': _productContent["default"],
  products: _productList["default"].Default,
  'products-controlled': _productList["default"].Controlled,
  'product-service': (0, _handleProductQA["default"])(_productQa["default"]),
  'product-video': _productVideo["default"],
  'draft-text': _draftText["default"],
  activity: _activity["default"],
  menu: (0, _menu.handleModuleData)(_menu["default"]),
  'smart-conversion': _smartConversion["default"]
};
exports["default"] = _default;