"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.symbol.js");

require("core-js/modules/es.symbol.description.js");

require("core-js/modules/es.array.is-array.js");

require("core-js/modules/es.array.map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = modifyWidgetDataInClient;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _v = _interopRequireDefault(require("uuid/v4"));

var _getLink = _interopRequireDefault(require("./getLink"));

var _getJustifyContent = _interopRequireDefault(require("./getJustifyContent"));

var _parseIframe = _interopRequireDefault(require("./parseIframe"));

var _parseGoogleMap = _interopRequireDefault(require("./parseGoogleMap"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * 暫時由前端join與filter各個module欄位
 * 希望以後後端直接處理...
 */
function modifyWidgetDataInClient() {
  var widgets = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var query = arguments.length > 1 ? arguments[1] : undefined;
  var page = arguments.length > 2 ? arguments[2] : undefined;
  // eslint-disable-next-line no-warning-comments
  // FIXME: prevent malformed widget data
  if (!Array.isArray(widgets)) return [];
  var mWidgets = widgets === null || widgets === void 0 ? void 0 : widgets.map(function (widget) {
    if ((widget === null || widget === void 0 ? void 0 : widget.widgets) == null) {
      switch (widget.module) {
        /* 商品列表 */

        /* products 為預設商品列表版型，不可刪除 */

        /* products-controlled 為店家自定義商品列表版型，可刪除 */
        case 'products':
          {
            var id = widget.id,
                contentWidth = widget.contentWidth,
                padding = widget.padding,
                productWidth = widget.productWidth,
                params = widget.params,
                alignItems = widget.alignItems,
                alignment = widget.alignment,
                cartButton = widget.cartButton,
                justifyContent = widget.justifyContent,
                showDescription = widget.showDescription,
                showPrice = widget.showPrice,
                showTitle = widget.showTitle,
                showSort = widget.showSort,
                notBeDeleted = widget.notBeDeleted,
                customTracking = widget.customTracking,
                type = widget.type,
                popUpGalleryView = widget.popUpGalleryView,
                mobileLayout = widget.mobileLayout,
                listStyle = widget.listStyle,
                cartBtnStyle = widget.cartBtnStyle,
                rowNum = widget.rowNum,
                rowWidth = widget.rowWidth,
                gap = widget.gap,
                showSellingPriceColor = widget.showSellingPriceColor,
                imgAspectRatio = widget.imgAspectRatio;
            return {
              id: id,
              module: notBeDeleted ? 'products' : 'products-controlled',
              contentWidth: contentWidth,
              params: params,
              showSort: showSort,
              alignment: alignment,
              justifyContent: justifyContent,
              alignItems: alignItems,
              showTitle: showTitle,
              showDescription: showDescription,
              showPrice: showPrice,
              cartButton: cartButton,
              productWidth: productWidth,
              customTracking: customTracking,
              padding: padding,
              type: type,
              popUpGalleryView: popUpGalleryView,
              mobileLayout: mobileLayout,
              listStyle: listStyle,
              cartBtnStyle: cartBtnStyle,
              rowNum: rowNum,
              rowWidth: rowWidth,
              gap: gap,
              showSellingPriceColor: showSellingPriceColor,
              imgAspectRatio: imgAspectRatio
            };
          }

        /* 產品主圖 */

        case 'product-carousell':
          {
            var module = widget.module,
                carouselInfo = widget.carouselInfo,
                _id = widget.id;
            return {
              id: _id || (0, _v["default"])(),
              module: module,
              productCarouselType: carouselInfo && carouselInfo.thumbsPosition === 'bottom' ? 'BOTTOM' : 'NONE',
              autoPlay: carouselInfo ? Boolean(carouselInfo.autoPlay) : false,
              customTracking: widget.customTracking
            };
          }

        /* 產品資訊 */

        case 'product-info':
          {
            var _widget$drawerOnMobil, _widget$unfoldedVaria;

            return {
              id: widget.id || (0, _v["default"])(),
              module: widget.module,
              contentWidth: widget.contentWidth,
              showButton: widget.showButton,
              drawerOnMobile: (_widget$drawerOnMobil = widget.drawerOnMobile) !== null && _widget$drawerOnMobil !== void 0 ? _widget$drawerOnMobil : false,
              unfoldedVariantsOnMobile: (_widget$unfoldedVaria = widget.unfoldedVariantsOnMobile) !== null && _widget$unfoldedVaria !== void 0 ? _widget$unfoldedVaria : true,
              customTracking: widget.customTracking
            };
          }

        /* 產品照片集 */

        case 'product-collections':
          {
            return {
              id: widget.id || (0, _v["default"])(),
              module: widget.module,
              productCollectionsType: widget.align === 'side' ? 'SIDE' : 'ORIGIN',
              percentWidth: "WIDTH".concat(widget.width || 70),
              gap: widget.gap || '24px',
              customTracking: widget.customTracking
            };
          }

        /* 產品語法嵌入 */

        case 'product-html':
          {
            return {
              id: widget.id || (0, _v["default"])(),
              module: widget.module,
              contentWidth: widget.contentWidth,
              customTracking: widget.customTracking
            };
          }

        /* 產品影片嵌入 */

        case 'product-video':
          return _objectSpread(_objectSpread({}, widget), {}, {
            ratio: {
              '16:9': 'Ratio16to9',
              '4:3': 'Ratio4to3',
              '16:10': 'Ratio16to10'
            }[widget.ratio]
          });

        /* 商品問答 */

        case 'product-service':
          {
            return {
              id: widget.id || (0, _v["default"])(),
              module: widget.module,
              width: widget.contentWidth,
              customTracking: widget.customTracking
            };
          }

        /* 圖片 */

        case 'image':
          {
            var _widget$files;

            return _objectSpread(_objectSpread({}, (_widget$files = widget.files) === null || _widget$files === void 0 ? void 0 : _widget$files[0]), {}, {
              id: widget.id || (0, _v["default"])(),
              module: widget.module,
              customTracking: widget.customTracking,
              alignment: widget.alignment,
              contentWidth: widget.contentWidth,
              mobileContentWidth: widget.mobileContentWidth,
              newWindow: widget.newWindow,
              files: widget.files,
              alt: widget.alt
            });
          }

        /* 語法嵌入 */

        case 'iframe':
          {
            return {
              id: widget.id || (0, _v["default"])(),
              module: widget.module,
              customTracking: widget.customTracking,
              htmlCode: widget.htmlCode || ''
            };
          }

        /* 地圖 */

        case 'googlemap':
          {
            return {
              id: widget.id || (0, _v["default"])(),
              module: widget.module,
              width: widget.width,
              height: widget.height,
              customTracking: widget.customTracking,
              href: (0, _parseGoogleMap["default"])(widget.href) || 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.592760581696!2d121.51357035073431!3d25.013950245169866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a9ea5f5c8e0d%3A0x6dda897a7281e0e3!2z6YGU6IqZ5aau!5e0!3m2!1szh-TW!2stw!4v1464849891494'
            };
          }

        /* 輪播圖 */

        case 'carousel':
          {
            return {
              id: widget.id || (0, _v["default"])(),
              module: widget.module,
              contentWidth: widget.contentWidth,
              htmlCode: widget.htmlCode,
              enableAutoplay: widget.enableAutoplay,
              enableIndicators: widget.enableIndicators,
              enableControls: widget.enableControls,
              pauseWhenHover: widget.pauseWhenHover,
              files: widget.files,
              newWindow: widget.newWindow,
              customTracking: widget.customTracking,
              alt: widget.alt
            };
          }

        /* 分隔線 */

        case 'divider':
          {
            var width = widget.width,
                height = widget.height,
                _alignment = widget.alignment,
                radius = widget.radius,
                background = widget.background;
            return {
              id: widget.id || (0, _v["default"])(),
              module: widget.module,
              width: width < 1 ? 1 : Math.round(width),
              height: height < 1 ? 1 : Math.round(height),
              justifyContent: (0, _getJustifyContent["default"])(_alignment),
              borderRadius: radius < 0 ? 0 : radius || 0,
              background: !background ? null : background
            };
          }

        /* 直播留言 */

        case 'liveVideoComments':
          {
            return {
              id: widget.id || (0, _v["default"])(),
              module: widget.module,
              contentWidth: widget.contentWidth,
              liveVideo: widget.liveVideo
            };
          }

        /* 影片嵌入 */

        case 'video-core':
          {
            return {
              id: widget.id || (0, _v["default"])(),
              module: widget.module,
              width: widget.contentWidth,
              customTracking: widget.customTracking,
              ratio: {
                '16:9': 'Ratio16to9',
                '4:3': 'Ratio4to3',
                '16:10': 'Ratio16to10'
              }[widget.aspect],
              href: (0, _parseIframe["default"])(widget.href) || 'https://www.youtube.com/watch?v=L8FK64bLJKE&feature=youtu.be'
            };
          }

        /* 臉書按讚 */

        case 'social-thumbs':
          {
            return {
              id: widget.id || (0, _v["default"])(),
              module: widget.module,
              href: (0, _getLink["default"])(widget.socialMediaInfo.share.url) || 'https://www.google.com.tw',
              justifyContent: (0, _getJustifyContent["default"])(widget.socialMediaInfo.alignItems),
              customTracking: widget.customTracking
            };
          }

        /* 臉書牆 */

        case 'facebook-wall':
          {
            return {
              id: widget.id || (0, _v["default"])(),
              module: widget.module,
              href: (0, _getLink["default"])(widget.href) || 'https://www.facebook.com/meepshop',
              justifyContent: (0, _getJustifyContent["default"])(widget.alignment),
              customTracking: widget.customTracking,
              showPosts: Boolean(widget.show_posts),
              showFacepile: Boolean(widget.show_facepile),
              smallHeader: Boolean(widget.small_header),
              hideCover: Boolean(widget.hide_cover),
              hideCta: Boolean(widget.hide_cta)
            };
          }

        /* 一頁式購物車 */

        case 'landing-page':
          {
            return {
              id: widget.id || (0, _v["default"])(),
              module: widget.module,
              contentWidth: widget.width,
              redirectPage: widget.landingInfo.redirectPage || '/',
              addition: widget.landingInfo.addition,
              shippableCountries: widget.landingInfo.shippableCountries || [],
              required: widget.landingInfo.required,
              params: widget.params,
              agreedMatters: widget.landingInfo.agreedMatters,
              paymentFilter: widget.landingInfo.paymentId || [],
              shipmentFilter: widget.landingInfo.shipmentId || [],
              customTracking: widget.customTracking
            };
          }

        /* 社群分享 */

        case 'social-media':
          {
            var socialMediaTypes = ['NONE', 'CIRCLE', 'CIRCLE_FILLED', 'ORIGIN'];

            var toBoolean = function toBoolean(value) {
              return value || value === null || false;
            };

            var _widget$socialMediaIn = widget.socialMediaInfo,
                _alignItems = _widget$socialMediaIn.alignItems,
                color = _widget$socialMediaIn.color,
                iconStyle = _widget$socialMediaIn.iconStyle,
                enableFacebook = _widget$socialMediaIn.enableFacebook,
                enableLine = _widget$socialMediaIn.enableLine,
                enableWechat = _widget$socialMediaIn.enableWechat,
                enableTwitter = _widget$socialMediaIn.enableTwitter;
            return {
              id: widget.id || (0, _v["default"])(),
              module: widget.module,
              socialMediaType: socialMediaTypes[iconStyle] || socialMediaTypes[0],
              justifyContent: {
                left: 'FLEX_START',
                center: 'CENTER',
                right: 'FLEX_END'
              }[_alignItems || 'left'],
              color: color || '#757575',
              showFacebook: toBoolean(enableFacebook),
              showLine: toBoolean(enableLine),
              showWechat: toBoolean(enableWechat),
              showTwitter: toBoolean(enableTwitter),
              customTracking: widget.customTracking
            };
          }

        /* 圖文 */

        case 'imagetext':
          {
            return {
              id: widget.id || (0, _v["default"])(),
              module: widget.module,
              contentWidth: widget.contentWidth,
              mobileContentWidth: widget.mobileContentWidth,
              button: widget.button,
              description: widget.description,
              header: widget.header,
              overlayBackgroundColor: widget.overlayBackgroundColor,
              position: widget.position,
              textColor: widget.textColor,
              buttonHoverColor: widget.buttonHoverColor || '#ffffff',
              showOverlay: widget.showOverlay,
              files: widget.files,
              fontFamily: widget.fontFamily,
              newWindow: widget.newWindow,
              customTracking: widget.customTracking,
              alt: widget.alt
            };
          }

        /* 文字 */

        case 'draft-text':
          {
            return {
              id: widget.id || (0, _v["default"])(),
              module: widget.module,
              content: widget.value || '',
              customTracking: widget.customTracking
            };
          }

        /* 折扣活動 */

        case 'activity':
          {
            return {
              id: widget.id || (0, _v["default"])(),
              module: widget.module,
              contentWidth: widget.contentWidth,
              background: widget.background,
              value: widget.value,
              customTracking: widget.customTracking // activity join in reselector

            };
          }

        /* 智慧轉換 */

        case 'smart-conversion':
          {
            var smartConversionModule = page.smartConversionModule;
            return _objectSpread(_objectSpread({}, widget), smartConversionModule);
          }

        default:
          return widget;
      }
    }

    return {
      id: (0, _v["default"])(),
      widgets: modifyWidgetDataInClient(widget === null || widget === void 0 ? void 0 : widget.widgets, query, page)
    };
  });
  return mWidgets;
}