"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAdTrack = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

var _head = require("./head");

var _useFbq = require("./useFbq");

var _useRetention = require("./useRetention");

var _useAddToCart = require("./useAddToCart");

var _useViewProduct = require("./useViewProduct");

var _useSearch = require("./useSearch");

var _useCompleteRegistration = require("./useCompleteRegistration");

var _useBeginCheckout = require("./useBeginCheckout");

var _usePurchase = require("./usePurchase");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query getAdTrack {\n    viewer {\n      id\n      email\n      role\n      name\n      mobile\n      store {\n        id\n        ...useFbqFragment\n        ...useRetentionFragment\n        ...usePurchaseFragment\n        ...headFragment\n        ...useBeginCheckoutFragment\n        ...useCompleteRegistrationFragment\n        adTracks {\n          ...useAddToCartFragment\n          ...useViewProductFragment\n          ...useSearchFragment\n        }\n      }\n    }\n  }\n\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var getAdTrack = (0, _client.gql)(_templateObject(), _head.headFragment, _useFbq.useFbqFragment, _useRetention.useRetentionFragment, _useAddToCart.useAddToCartFragment, _useViewProduct.useViewProductFragment, _useSearch.useSearchFragment, _useCompleteRegistration.useCompleteRegistrationFragment, _useBeginCheckout.useBeginCheckoutFragment, _usePurchase.usePurchaseFragment);
exports.getAdTrack = getAdTrack;