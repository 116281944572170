"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.slice.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Trans = exports.useTranslation = exports.i18n = exports.appWithTranslation = exports.withTranslation = void 0;

var _nextI18next = _interopRequireDefault(require("next-i18next"));

var _path = _interopRequireDefault(require("path"));

var _constants = require("./constants");

// import
// definition
var nextI18next = new _nextI18next["default"]({
  defaultLanguage: _constants.languages[0],
  otherLanguages: _constants.languages.slice(1),
  localePath: typeof window === 'undefined' ? _path["default"].resolve('src/public/locales') : _path["default"].resolve('locales'),
  // we use 'null' to fall back
  fallbackLng: _constants.languages[0],
  serverLanguageDetection: false,
  returnNull: false
});
var withTranslation = nextI18next.withTranslation,
    appWithTranslation = nextI18next.appWithTranslation,
    i18n = nextI18next.i18n,
    useTranslation = nextI18next.useTranslation,
    Trans = nextI18next.Trans;
exports.Trans = Trans;
exports.useTranslation = useTranslation;
exports.i18n = i18n;
exports.appWithTranslation = appWithTranslation;
exports.withTranslation = withTranslation;